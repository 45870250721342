import { useAuth } from 'hooks/auth';
import PaymentMethodsPage from 'pages/PaymentMethods';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../pages/AccessControl';
import NewProfile from '../pages/AccessControl/NewProfile';
import ConfirmEmail from '../pages/ConfirmEmail';
import ConfirmRegister from '../pages/ConfirmRegister';
import CookiesPolicy from '../pages/CookiesPolicy';
import Drivers from '../pages/Driver/Drivers';
import RegisterDriver from '../pages/Driver/RegisterDriver';
import Financial from '../pages/Financial';
import FinesViolations from '../pages/FinesViolations';
import ForgotPassword from '../pages/ForgotPassword';
import MyRequests from '../pages/MyRequests';
import NewRequest from '../pages/MyRequests/NewRequest';
import MyVehicles from '../pages/MyVehicles';
import Orders from '../pages/Orders';
import Panel from '../pages/Panel';
import Reports from '../pages/Reports';
import ResetPassword from '../pages/ResetPassword';
import Users from '../pages/Users';
import UserMaintenance from '../pages/Users/UserMaintenance';
import Welcome from '../pages/Welcome';
import PrivateRoute from './PrivateRoute';
import LandingPage from '../pages/Home';
import VehicleDetails from 'pages/MyVehicles/VehicleDetails';

function Routes() {
  const { fullname } = useAuth();
  const isLogged = !!fullname;

  return (
    <Switch>
      {/* public */}
      <Route
        path="/"
        render={() =>
          isLogged ? <Redirect to={'/welcome'} /> : <LandingPage />
        }
        exact
      />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:token?" component={ResetPassword} />
      <Route path="/confirm-register" component={ConfirmRegister} />
      <Route path="/confirm-email/:token" component={ConfirmEmail} />
      <Route path="/polcookies" component={CookiesPolicy} />
      {/* private */}
      <PrivateRoute path={'/welcome'} component={Welcome} />
      <PrivateRoute path={'/panel'} component={Panel} />
      <PrivateRoute path={'/reports'} component={Reports} />
      <PrivateRoute path={'/orders'} component={Orders} />
      <PrivateRoute path={'/access-control'} component={AccessControl} />
      <PrivateRoute path={'/new-profile/:id?'} component={NewProfile} />
      <PrivateRoute path={'/profile'} component={Users} />
      <PrivateRoute path={'/financial/:id?'} component={Financial} />
      <PrivateRoute path={'/fines-violations'} component={FinesViolations} />
      <PrivateRoute path={'/my-vehicles'} component={MyVehicles} exact />
      <PrivateRoute
        path={'/my-vehicles/:plaqueId'}
        component={VehicleDetails}
      />
      <PrivateRoute
        path={'/register-driver/:add?'}
        component={RegisterDriver}
      />
      <PrivateRoute path={'/edit-driver'} component={RegisterDriver} />
      <PrivateRoute path={'/drivers'} component={Drivers} />
      <PrivateRoute path={'/my-requests'} component={MyRequests} />
      <PrivateRoute path={'/new-request/:tabName'} component={NewRequest} />
      <PrivateRoute
        path={'/user-maintenance/:id?'}
        component={UserMaintenance}
      />
      <PrivateRoute path={'/credit-card'} component={PaymentMethodsPage} />
      <Redirect from="*" to={isLogged ? '/welcome' : ''} />
    </Switch>
  );
}

export default Routes;
