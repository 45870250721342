import React, { useRef } from 'react';
import CreditCard from './CreditCard';
import axios, { AxiosResponse } from 'axios';
import AdyenPaymentMethod from './AdyenPaymentMethod';
import {
  BANK_SLIP_PAYMENT_METHOD_ID,
  CREDIT_CARD_PAYMENT_METHOD_ID,
  CurrentPaymentMethodI,
  DisabledOptionsI,
} from '..';
import AdyenCheckout from '@adyen/adyen-web';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { OnPaymentCompletedData } from '@adyen/adyen-web/dist/types/components/types';
import { getProductFromUrl } from 'pages/LandingPages/utils/helpers';

const MERCHANT_ACCOUNT = {
  lmassinecar: 'Whitelabel-AC',
  vwsignanddrive: 'SignAndDrive',
  audisignature: 'LMMobilidadeBR',
  rentalway: 'RentalWay',
};

interface PaymentSessionI {
  id: string;
  sessionData: string;
}
interface PaymentSessionResponseI {
  data: PaymentSessionI;
  statusCode: number;
  success: boolean;
}
interface HistoryI {
  recurringDetailReference: string;
}
interface HistoryResponseI {
  data: HistoryI;
  statusCode: number;
  success: boolean;
}

type Props = {
  payment: CurrentPaymentMethodI;
  selectedOption: string;
  disabledOptions: DisabledOptionsI;
  initPaymentMethod: (contractNumber: string) => Promise<void>;
  onSelectMethod: (value: string) => void;
  setLoading: (value: boolean) => void;
};

function ChangePaymentMethod({
  payment,
  selectedOption,
  disabledOptions,
  initPaymentMethod,
  onSelectMethod,
  setLoading,
}: Props) {
  const dropinRef = useRef<HTMLDivElement>(null);

  const handleSaveBankSlip = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_HOST_PAYMENT}/v2/EuroIT/Payments/Methods/Save`,
        {
          contractNumber: payment?.numeroContrato,
          paymentMethod: BANK_SLIP_PAYMENT_METHOD_ID,
          clientId: payment?.clienteCpf,
          tokenId: '',
          creditCardUpdated: false,
        }
      );
    } finally {
      setLoading(false);
      initPaymentMethod(payment?.numeroContrato);
    }
  };

  const fetchHistory = async () => {
    const RETRY_DELAY_MS = 3500;
    const MAX_ATTEMPS = 3;

    for (let attemps = 1; attemps <= MAX_ATTEMPS; attemps++) {
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
      try {
        const response: AxiosResponse<HistoryResponseI> = await axios.post(
          `${process.env.REACT_APP_HOST_PAYMENT}/v1/Payment/Checkout/History`,
          { shopperReference: payment?.clienteCpf }
        );
        // a adyen já comunicou o motor de cobrança
        if (
          response?.data?.data?.recurringDetailReference !==
          payment?.cartaoToken
        ) {
          return response;
        }
      } catch {
        throw 'Não foi possível carregar o histórico.';
      }
    }
  };

  const fetchTokenPayment = async (
    paymentCompletedData: OnPaymentCompletedData
  ) => {
    if (paymentCompletedData.resultCode !== 'Authorised') return; // exibi erro da adyen
    setLoading(true);
    try {
      const historyResponse = await fetchHistory();
      if (historyResponse) {
        await axios.post(
          `${process.env.REACT_APP_HOST_PAYMENT}/v2/EuroIT/Payments/Methods/Save`,
          {
            contractNumber: payment?.numeroContrato,
            paymentMethod: CREDIT_CARD_PAYMENT_METHOD_ID,
            clientId: payment?.clienteCpf,
            tokenId: historyResponse.data?.data?.recurringDetailReference,
            creditCardUpdated: true,
          }
        );
      }
    } finally {
      initPaymentMethod(payment?.numeroContrato);
      setLoading(false);
    }
  };

  const fetchPaymentSession = async (cpfCnpj: string) => {
    try {
      const productKey = getProductFromUrl();
      const response: AxiosResponse<PaymentSessionResponseI> = await axios.post(
        `${process.env.REACT_APP_HOST_PAYMENT}/v1/Payment/Sessions`,
        {
          merchantAccount:
            MERCHANT_ACCOUNT[productKey as keyof typeof MERCHANT_ACCOUNT],
          amountCharged: '0',
          clientDocumentNumber: cpfCnpj,
        }
      );
      return response?.data?.data;
    } catch {
      return null;
    }
  };

  const initAdyenCheckout = async (
    session: PaymentSessionI,
    dropinElement: HTMLDivElement | string
  ) => {
    try {
      const configuration: CoreOptions = {
        locale: 'pt-br',
        environment: process.env.REACT_APP_PAYMENT_ENVIRONMENT,
        clientKey: process.env.REACT_APP_PAYMENT_CLIENT_KEY,
        session: session,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: false,
            name: 'Novo',
          },
        },
        amount: {
          value: 0,
          currency: 'BRL',
        },
        translations: {
          'pt-BR': {
            'creditCard.success': 'Cartão de crédito cadastrado com sucesso.',
            'error.message.unknown':
              'Cartão não cadastrado. Verifique os dados do cartão e tente novamente.',
            'resultMessages.preauthorized': 'Aguarde um instante...',
            confirmPreauthorization: 'Salvar informações',
          },
        },
        onPaymentCompleted: fetchTokenPayment,
        onError: () => {
          initPaymentMethod(payment?.numeroContrato);
        },
      };
      const checkout = await AdyenCheckout(configuration);
      checkout.create('dropin').mount(dropinElement);
    } catch {}
  };

  const handleSelectCreditCard = async (value: string) => {
    setLoading(true);
    onSelectMethod(value);
    try {
      const sessionData = await fetchPaymentSession(payment?.clienteCpf);
      if (!sessionData || !dropinRef.current) return;
      await initAdyenCheckout(sessionData, dropinRef.current);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ul>
      <AdyenPaymentMethod
        value="bankSlip"
        label="Boleto bancário"
        instruction='Ao clicar em "Salvar informações" a forma de pagamento do seu
              contrato será alterada para boleto bancário.'
        selected={selectedOption === 'bankSlip'}
        disabled={disabledOptions.bankSlip}
        onClick={onSelectMethod}
      >
        <button
          className="adyen-checkout__button adyen-checkout__button--pay"
          type="button"
          onClick={handleSaveBankSlip}
        >
          <span className="adyen-checkout__button__content">
            <img
              className="adyen-checkout__button__icon"
              src="https://checkoutshopper-test.adyen.com/checkoutshopper/images/components/lock.svg"
              aria-hidden="true"
            />
            <span>Salvar informações</span>
          </span>
        </button>
      </AdyenPaymentMethod>
      <AdyenPaymentMethod
        value="creditCard"
        label="Cartão de crédito"
        instruction='Ao clicar em "Salvar informações" a forma de pagamento do seu
                contrato será alterada para cartão de crédito.'
        selected={selectedOption === 'creditCard'}
        disabled={disabledOptions.creditCard}
        onClick={handleSelectCreditCard}
      >
        <CreditCard dropinRef={dropinRef} />
      </AdyenPaymentMethod>
    </ul>
  );
}

export default ChangePaymentMethod;
