import {
  ACTIVE_FLEET_REPORT_NAME,
  CONDUCTORS_REPORT_NAME,
  FORMAT_VALUES,
  REQUEST_REPORT_NAME,
  SPARE_CARS_REPORT_NAME,
  TRAFFIC_TICKET_REPORT_NAME,
} from './constants';
import activeFleetExcelSample from 'assets/sample/active-fleet-report-sample.xlsx';
import requestsExcelSample from 'assets/sample/requests-report-sample.xlsx';
import spareCarsExcelSample from 'assets/sample/spare-cars-report-sample.xlsx';
import trafficTicketExcelSample from 'assets/sample/traffic-ticket-report-sample.xlsx';
import conductorsExcelSample from 'assets/sample/conductors-report-sample.xlsx';
import {
  ActiveFleetT,
  ConductorsT,
  RequestsT,
  SpareCarsT,
  TrafficTicketT,
} from '../types';
import moment from 'moment';
import formatValue from 'utils/formatValue';

export const activeFleetConfig = {
  apiUrl: '/crm/reports/activefleet',
  columns: [
    { key: 'contrato', title: 'Contrato' },
    { key: 'placa', title: 'Placa' },
    { key: 'modelo', title: 'Modelo' },
    { key: 'kmAtual', title: 'Km Atual' },
    { key: 'classificacaoFrota', title: 'Classificação' },
    { key: 'cor', title: 'Cor' },
    { key: 'acessoriosSigla', title: 'Sigla Acessórios' },
    { key: 'numeroChassi', title: 'Chassi' },
    { key: 'numeroRenavam', title: 'Renavam' },
    { key: 'ano', title: 'Ano' },
    { key: 'deposito', title: 'Depósito' },
  ],
  excelSample: activeFleetExcelSample,
  title: ACTIVE_FLEET_REPORT_NAME,
  createExcelRow: (row: ActiveFleetT) => [
    row.contrato,
    row.placa,
    row.modelo,
    row.kmAtual,
    row.classificacaoFrota,
    row.cor,
    row.acessoriosSigla,
    row.numeroChassi,
    row.numeroRenavam,
    row.ano,
    row.deposito,
  ],
};

export const requestsConfig = {
  apiUrl: '/crm/reports/solicitations',
  columns: [
    { key: 'veiculoPlaca', title: 'Placa' },
    {
      key: 'dataOcorrencia',
      title: 'Data Ocorrência',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'modeloVeiculo', title: 'Modelo' },
    { key: 'numeroOcorrencia', title: 'N° Ocorrência' },
    { key: 'tipoOcorrencia', title: 'Ocorrência' },
    { key: 'km', title: 'KM' },
    { key: 'kmAtual', title: 'KM Atual' },
    { key: 'pecaServico', title: 'Peça/Serviço' },
    { key: 'fornecedor', title: 'Fornecedor' },
    { key: 'statusOcorrencia', title: 'Status Ocorrência' },
    {
      key: 'dataAgendamento',
      title: 'Data Agendamento',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataEntradaOficina',
      title: 'Data Entrada Oficina',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataConclusaoAgendamento',
      title: 'Data Conclusão Agendamento',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
  ],
  excelSample: requestsExcelSample,
  title: REQUEST_REPORT_NAME,
  createExcelRow: (row: RequestsT) => {
    return [
      row.veiculoPlaca,
      moment(row.dataOcorrencia).format(FORMAT_VALUES.fullDateTime),
      row.modeloVeiculo,
      row.numeroOcorrencia,
      row.tipoOcorrencia,
      row.km,
      row.kmAtual,
      row.pecaServico,
      row.quantidade,
      row.fornecedor,
      row.statusOcorrencia,
      moment(row.dataAgendamento).format(FORMAT_VALUES.fullDateTime),
      moment(row.dataEntradaOficina).format(FORMAT_VALUES.fullDateTime),
      moment(row.dataConclusaoAgendamento).format(FORMAT_VALUES.fullDateTime),
    ];
  },
};

export const spareCarsConfig = {
  apiUrl: '/crm/reports/spare-car',
  columns: [
    { key: 'placaOcorrencia', title: 'Placa' },
    {
      key: 'dataOcorrencia',
      title: 'Data Ocorrência',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'modeloVeiculoOcorrencia', title: 'Modelo Veículo' },
    { key: 'ocorrenciaId', title: 'N° Ocorrência' },
    { key: 'tipoOcorrencia', title: 'Tipo Ocorrência' },
    { key: 'kmOcorrencia', title: 'KM' },
    { key: 'kmAtualVeiculo', title: 'KM Atual' },
    { key: 'fornecedor', title: 'Fornecedor' },
    { key: 'statusOcorrencia', title: 'Status Ocorrência' },
    {
      key: 'dataAgendamento',
      title: 'Data Agendamento',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataEntradaOficina',
      title: 'Data Entrada Oficina',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataConclusaoAgendamento',
      title: 'Data Conclusão Agendamento',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'solicitacaoId', title: 'Solicitação Reserva' },
    { key: 'modeloAtendimentoReserva', title: 'Modelo Reserva' },
    { key: 'placaAtendimentoReserva', title: 'Placa Veículo Reserva' },
    {
      key: 'dataInicioReserva',
      title: 'Data início reserva',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataDevolucaoReserva',
      title: 'Data devolução reserva',
      render: (value: any) => moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'statusReserva', title: 'Status Reserva' },
    { key: 'cidade', title: 'Cidade Sublocação' },
    { key: 'estado', title: 'UF Sublocação' },
  ],
  excelSample: spareCarsExcelSample,
  title: SPARE_CARS_REPORT_NAME,
  createExcelRow: (row: SpareCarsT) => {
    return [
      row.placaOcorrencia,
      moment(row.dataOcorrencia).format(FORMAT_VALUES.fullDateTime),
      row.modeloVeiculoOcorrencia,
      row.ocorrenciaId,
      row.tipoOcorrencia,
      row.kmOcorrencia,
      row.kmAtualVeiculo,
      row.fornecedor,
      row.statusOcorrencia,
      moment(row.dataAgendamento).format(FORMAT_VALUES.fullDateTime),
      moment(row.dataEntradaOficina).format(FORMAT_VALUES.fullDateTime),
      moment(row.dataConclusaoAgendamento).format(FORMAT_VALUES.fullDateTime),
      row.solicitacaoId,
      row.modeloAtendimentoReserva,
      row.placaAtendimentoReserva,
      moment(row.dataInicioReserva).format(FORMAT_VALUES.fullDateTime),
      moment(row.dataDevolucaoReserva).format(FORMAT_VALUES.fullDateTime),
      row.statusReserva,
      row.cidade,
      row.estado,
    ];
  },
};

export const traffictTicketConfig = {
  apiUrl: '/crm/reports/traffic-violation',
  columns: [
    { key: 'veiculoPlaca', title: 'Placa' },
    { key: 'codigoMulta', title: 'Cód. Multa' },
    { key: 'autoInfracao', title: 'Infração' },
    { key: 'tipoMulta', title: 'Tipo Multa' },
    {
      key: 'dataInfracao',
      title: 'Data Infração',
      render: (value: string) =>
        moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'codigoInfracao', title: 'Cód. Infração' },
    { key: 'motorista', title: 'Motorista' },
    {
      key: 'dataEnvioEmail',
      title: 'Data Envio Email',
      render: (value: string) =>
        moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    {
      key: 'dataLimiteIndicacao',
      title: 'Data Limite Indicação',
      render: (value: string) =>
        moment(value).format(FORMAT_VALUES.fullDateTime),
    },
    { key: 'descricaoInfracao', title: 'Descrição Infração' },
    { key: 'estado', title: 'Estado' },
    { key: 'cidade', title: 'Cidade' },
    { key: 'localInfracao', title: 'Local Infração' },
    {
      key: 'valorLiquido',
      title: 'Valor Liquído',
      render: (value: number) => formatValue(value),
    },
    {
      key: 'valorBruto',
      title: 'Valor Bruto',
      render: (value: number) => formatValue(value),
    },
    { key: 'grauInfracao', title: 'Grau Infração' },
    { key: 'pontuacaoInfracao', title: 'Pontuação Infração' },
  ],
  excelSample: trafficTicketExcelSample,
  title: TRAFFIC_TICKET_REPORT_NAME,
  createExcelRow: (row: TrafficTicketT) => [
    row.veiculoPlaca,
    row.codigoMulta,
    row.autoInfracao,
    row.tipoMulta,
    row.codigoInfracao,
    moment(row.dataInfracao).format(FORMAT_VALUES.fullDateTime),
    row.motorista,
    moment(row.dataEnvioEmail).format(FORMAT_VALUES.fullDateTime),
    moment(row.dataLimiteIndicacao).format(FORMAT_VALUES.fullDateTime),
    row.descricaoInfracao,
    row.estado,
    row.cidade,
    row.localInfracao,
    formatValue(row.valorLiquido),
    formatValue(row.valorBruto),
    row.grauInfracao,
    row.pontuacaoInfracao,
  ],
};

export const conductorsConfig = {
  apiUrl: '/crm/reports/conductors-vehicle',
  columns: [
    { key: 'placa', title: 'Placa' },
    { key: 'nomeCondutor', title: 'Nome' },
    { key: 'emailCondutor', title: 'E-mail' },
    { key: 'cpf', title: 'CPF' },
    { key: 'cnh', title: 'CNH' },
  ],
  excelSample: conductorsExcelSample,
  title: CONDUCTORS_REPORT_NAME,
  createExcelRow: (row: ConductorsT) => [
    row.placa,
    row.nomeCondutor,
    row.emailCondutor,
    row.cpf,
    row.cnh,
  ],
};
