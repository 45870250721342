import InputUI from 'components/ui/InputUI';
import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 73px);
  color: #3c505a;
`;

export const Content = styled.div`
  background: #fff;
  width: 90%;
  margin: 40px;
  padding: 25px;

  @media (min-width: 900px) {
    width: 830px;
  }

  h1,
  & > p,
  select {
    color: #0c5faf;
    font-weight: 500;
  }

  p:nth-child(3) {
    text-align: center;
    margin-top: 18px;
  }

  .wrapper-select {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    p {
      margin: 0 25px 0 0;
    }

    & > div {
      width: 100%;
      & > div {
        width: 100%;
      }
    }

    select {
      background-color: #fff;
      border: 2px solid #0c5faf;
      border-radius: 26px;
      width: 100%;
      color: #797979;
      padding-left: 15px;
      height: 40px;
    }
  }

  .loadingDataVehicle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 0px 0px 0px;
  }

  p {
    @media (max-width: 491px) {
      text-align: center;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  div {
    color: #000000;
    width: 100%;

    .wrapper-info {
      margin: 20px;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 15px;

        @media (max-width: 768px) {
          font-size: 13px;
        }

        &:last-child {
          width: 65%;
          text-align: left;

          @media (max-width: 491px) {
            width: 55%;
          }

          @media (max-width: 768px) {
            width: 60%;
          }
        }
      }
    }
  }

  .divClient {
    width: 500px !important;
  }

  .labelClient {
    width: 350px !important;
  }

  @media (max-width: 491px) {
    flex-direction: column;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media (max-width: 1166px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    .ant-spin {
      line-height: 0;
    }
  }

  @media (max-width: 491px) {
    & > div {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-size: 15px;
  color: #0c5faf;
  font-weight: 500;
  text-align: center;
`;

export const StyledSearchContainer = styled.div`
  max-width: 360px;
  margin-bottom: 15px;
`;

export const StyledDownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: wrap;
`;

export const StyledDownloadInfo = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.disabled && `color: #ce6509;`}
`;

export const StyledCheckbox = styled(InputUI)`
  cursor: pointer;
  height: 16px;
  width: 16px;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledAllCheckboxContainer = styled.div`
  & > div {
    line-height: 0;
  }
`;

export const StyledSelectedPlaques = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 15px;
  flex-wrap: wrap;
  height: 23px;

  & > div {
    font-size: 11px;
    border: 1px solid #ccc;
    padding: 1px 3px;
    width: 58px;
    height: 22px;
  }

  @media (max-width: 460px) {
    height: 48px;
  }
`;
