import React, { ChangeEvent, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { useAuth } from '../../hooks/auth';
import {
  Container,
  Content,
  StyledAllCheckboxContainer,
  StyledCheckbox,
  StyledDownloadContainer,
  StyledDownloadInfo,
  StyledSearchContainer,
  StyledSelectedPlaques,
  WrapperContent,
} from './styles';
import api from 'services/api';
import ButtonUI from 'components/ui/ButtonUI';
import { useToast } from 'hooks/toast';
import { createBlob, decodeBase64, downloadBlob } from 'utils/downloadFile';
import VehicleDetails from './VehicleDetails';
import { HiChevronLeft } from 'react-icons/hi';
import TablePaginationUI from 'components/ui/TablePaginationUI';
import { ITEMS_PER_PAGE } from 'pages/Reports/utils/constants';
import axios from 'axios';
import InputUI from 'components/ui/InputUI';

type PlaquesT = {
  veiculoId: number;
  veiculoPlaca: string;
  renavam: string;
};

const MAX_SELECTED_PLATES = 10;

function MyVehicles() {
  const { addToast } = useToast();
  const { userId } = useAuth();

  const [loading, setLoading] = useState(true);
  const [plaques, setPlaques] = useState<PlaquesT[]>([]);
  const [selectedPlaques, setSelectedPlaques] = useState<PlaquesT[]>([]);
  const [loadingCRLV, setLoadingCRLV] = useState(false);
  const [veiculoId, setVeiculoId] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [searchPlaque, setSearchPlaque] = useState('');

  const filteredPlaques = plaques.filter((plaque) =>
    plaque.veiculoPlaca.toLocaleUpperCase().includes(searchPlaque)
  );
  const selectedPlatesCount = selectedPlaques.length;
  console.log('searchPlaque', searchPlaque, filteredPlaques, page);

  useEffect(() => {
    setLoading(true);
    api
      .post(`/crm/reports/vehicle_plaque-by-customer_LM`, {
        userId: +userId,
        clienteId: null,
      })
      .then((response) => {
        setPlaques(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  const handleCheckbox = (isChecked: boolean, value: PlaquesT) => {
    setSelectedPlaques((prevState) => {
      if (isChecked) {
        return prevState.filter((item) => item.renavam !== value.renavam);
      }
      return [...prevState, value];
    });
  };

  const fetchCRLV = async () => {
    setLoadingCRLV(true);
    if (selectedPlatesCount === 0) {
      return;
    }
    try {
      if (selectedPlatesCount === 1) {
        const firstRenavam = selectedPlaques[0].renavam;
        const response = await api.get(`/crm/reports/getCRLV/${firstRenavam}`);
        if (response.data.base64) {
          downloadBlob(
            createBlob(decodeBase64(response.data.base64), 'application/pdf'),
            `CRLVe_${firstRenavam}.pdf`
          );
        } else {
          throw 'Não foi possível fazer download do CRLV.';
        }
      } else {
        const response = await api.get(
          `/crm/reports/crlv/downloadzip?renavam=${selectedPlaques
            .map((plaque) => plaque.renavam)
            .join('&renavam=')}`,
          { responseType: 'blob' }
        );
        downloadBlob(response.data, `CRLVs.zip`);
      }
    } catch (error) {
      const description = axios.isAxiosError(error)
        ? await error?.response?.data?.text()
        : error;
      addToast({
        title: 'CRLV',
        type: 'error',
        description: description,
      });
    } finally {
      setLoadingCRLV(false);
    }
  };

  const handleViewClick = (value: number) => setVeiculoId(value);

  const handlePaginate = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleSearchPlaque = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLocaleUpperCase();
    const regex = [
      /^[A-Z]$/, // 1º caractere: Letra
      /^[A-Z]$/, // 2º caractere: Letra
      /^[A-Z]$/, // 3º caractere: Letra
      /^[0-9]$/, // 4º caractere: Número
      /^[A-Z0-9]$/, // 5º caractere: Letra ou número
      /^[0-9]$/, // 6º caractere: Número
      /^[0-9]$/, // 7º caractere: Número
    ];

    if (value.length > regex.length) return;

    const currentLetter = value[value.length - 1];
    const currentRegex = regex[value.length - 1];

    if (value === '' || currentRegex.test(currentLetter)) {
      setSearchPlaque(value);
      setPage(1);
    }
  };

  const handleAllCheckbox = () => {
    const end = page * ITEMS_PER_PAGE;
    const start = end - ITEMS_PER_PAGE;
    const plaquesOfPage = filteredPlaques.slice(start, end);
    setSelectedPlaques(selectedPlatesCount ? [] : plaquesOfPage);
  };

  const columns = [
    {
      key: 'renavam',
      // renderTitle: () => (
      //   <StyledAllCheckboxContainer>
      //     <StyledCheckbox
      //       type="checkbox"
      //       checked={selectedPlatesCount > 0}
      //       disabled={loading}
      //       onChange={handleAllCheckbox}
      //     />
      //   </StyledAllCheckboxContainer>
      // ),
      render: (renavam: string, row: any) => {
        const isChecked = !!selectedPlaques.find(
          (plaque: PlaquesT) => plaque.renavam === renavam
        );
        return (
          <StyledCheckbox
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckbox(isChecked, row)}
            disabled={
              selectedPlatesCount === MAX_SELECTED_PLATES && !isChecked
                ? true
                : false
            }
          />
        );
      },
    },
    {
      key: 'veiculoPlaca',
      title: 'Placa',
    },
    {
      key: 'veiculoId',
      title: 'Detalhes do veículo',
      render: (veiculoIdValue: number) => (
        <ButtonUI color="third" onClick={() => handleViewClick(veiculoIdValue)}>
          Visualizar
        </ButtonUI>
      ),
    },
  ];

  const content = veiculoId ? (
    <>
      <ButtonUI color="secondary" onClick={() => setVeiculoId(0)}>
        <HiChevronLeft size={20} /> Voltar
      </ButtonUI>
      <VehicleDetails plaqueId={veiculoId} />
    </>
  ) : (
    <>
      {/* <StyledSearchContainer>
        <InputUI
          id="placa"
          name="placa"
          placeholder="Filtrar placa..."
          value={searchPlaque}
          maxLength={7}
          onChange={handleSearchPlaque}
        />
      </StyledSearchContainer> */}

      <StyledDownloadContainer>
        <StyledDownloadInfo
          disabled={selectedPlatesCount === MAX_SELECTED_PLATES}
        >
          {selectedPlatesCount} de {MAX_SELECTED_PLATES} placas selecionadas
          para fazer o download das CRLVs
        </StyledDownloadInfo>
        <ButtonUI
          color="secondary"
          disabled={selectedPlaques.length <= 0}
          loading={loadingCRLV}
          onClick={fetchCRLV}
        >
          Download CRLV
        </ButtonUI>
      </StyledDownloadContainer>
      {/* <StyledSelectedPlaques>
        {selectedPlaques.map((plaque) => (
          <div>{plaque.veiculoPlaca}</div>
        ))}
      </StyledSelectedPlaques> */}
      <TablePaginationUI
        columns={columns}
        data={filteredPlaques}
        itemsPerPage={ITEMS_PER_PAGE}
        loading={loading}
        noData="Não há placas disponíveis."
        page={page}
        total={filteredPlaques.length}
        onPaginate={handlePaginate}
      />
    </>
  );

  return (
    <>
      <Header />
      <Container>
        <Title
          title={'meus veículos'}
          subtitle={'Consulte aqui todos os dados do seu contrato'}
        />
        <WrapperContent>
          <Content>{content}</Content>
        </WrapperContent>
      </Container>
    </>
  );
}

export default MyVehicles;
