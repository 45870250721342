import React from 'react';

import Header from 'components/Header';
import Title from 'components/Title';
import { Content, Main } from 'pages/Reports/styles';
import ReportsMenu from 'pages/Reports/components/ReportsMenu';
import useReports from 'pages/Reports/hooks/useReports';
import ReportLayout from 'pages/Reports/components/ReportLayout';
import {
  activeFleetConfig,
  requestsConfig,
  spareCarsConfig,
  traffictTicketConfig,
  conductorsConfig,
} from './utils/config';

function Reports() {
  const { activeMenu, customers, handleMenuClick } = useReports();

  const renderReport = () => {
    switch (activeMenu) {
      case activeFleetConfig.title:
        return (
          <ReportLayout
            apiUrl={activeFleetConfig.apiUrl}
            columns={activeFleetConfig.columns}
            customers={customers}
            excelSample={activeFleetConfig.excelSample}
            reportTitle={activeFleetConfig.title}
            key={activeFleetConfig.title}
            createExcelRow={activeFleetConfig.createExcelRow}
          />
        );
      case traffictTicketConfig.title:
        return (
          <ReportLayout
            apiUrl={traffictTicketConfig.apiUrl}
            columns={traffictTicketConfig.columns}
            customers={customers}
            excelSample={traffictTicketConfig.excelSample}
            reportTitle={traffictTicketConfig.title}
            key={traffictTicketConfig.title}
            createExcelRow={traffictTicketConfig.createExcelRow}
          />
        );
      case requestsConfig.title:
        return (
          <ReportLayout
            apiUrl={requestsConfig.apiUrl}
            columns={requestsConfig.columns}
            customers={customers}
            excelSample={requestsConfig.excelSample}
            reportTitle={requestsConfig.title}
            key={requestsConfig.title}
            createExcelRow={requestsConfig.createExcelRow}
          />
        );
      case spareCarsConfig.title:
        return (
          <ReportLayout
            apiUrl={spareCarsConfig.apiUrl}
            columns={spareCarsConfig.columns}
            customers={customers}
            excelSample={spareCarsConfig.excelSample}
            reportTitle={spareCarsConfig.title}
            key={spareCarsConfig.title}
            createExcelRow={spareCarsConfig.createExcelRow}
          />
        );
      case conductorsConfig.title:
        return (
          <ReportLayout
            apiUrl={conductorsConfig.apiUrl}
            columns={conductorsConfig.columns}
            customers={customers}
            excelSample={conductorsConfig.excelSample}
            reportTitle={conductorsConfig.title}
            key={conductorsConfig.title}
            createExcelRow={conductorsConfig.createExcelRow}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <Main>
        <ReportsMenu active={activeMenu} onClick={handleMenuClick} />
        <Content>
          <Title title={'RELATÓRIOS'} subtitle="" />
          {renderReport()}
        </Content>
      </Main>
    </>
  );
}

export default Reports;
