import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { Info } from './styles';
import formatValue from '../../utils/formatValue';
import moment from 'moment';
import { useToast } from '../../hooks/toast';
import LocationSpin from '../../components/LoadingSpin';

type Props = {
  plaqueId: number;
};

function VehicleDetails({ plaqueId }: Props) {
  const { addToast } = useToast();
  const { userId } = useAuth();

  const [statusContracts, setStatusContracts] = useState<any>();
  const [odometer, setOdometer] = useState('');
  const [loadingSkinDataVehicle, setLoadingSkinDataVehicle] = useState(false);

  useEffect(() => {
    const getVehicleByPlaque = async () => {
      if (!plaqueId) {
        setStatusContracts(null);
        return;
      }
      setStatusContracts(null);
      setLoadingSkinDataVehicle(true);
      api
        .get(
          `/crm/reports/vehicle_by_plaque-by-customer_LM/${userId}/${plaqueId}`
        )
        .then((response) => {
          if (response.data.length == 0) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: 'A placa informada não foi encontrada!',
            });
            setLoadingSkinDataVehicle(false);
            return;
          }
          setStatusContracts(response.data[0]);
          setOdometer(response.data[0].kmAtual);
          setLoadingSkinDataVehicle(false);
        })
        .catch(() => {
          setLoadingSkinDataVehicle(false);
        });
    };
    getVehicleByPlaque();
  }, [plaqueId, userId]);

  if (!statusContracts) {
    return (
      <div className="loadingDataVehicle">
        <LocationSpin loading={loadingSkinDataVehicle} />
      </div>
    );
  }

  return (
    <div className="info-car">
      <Info>
        <div>
          <div className="wrapper-info">
            <label>Cliente</label>
            <label>
              {statusContracts?.clientesNome
                ? statusContracts?.clientesNome
                : '--'}
            </label>
          </div>
        </div>
        <div>
          <div className="wrapper-info">
            <label>Status</label>
            <label>
              {statusContracts?.statusVeiculoContratoNome
                ? statusContracts?.statusVeiculoContratoNome
                : '--'}
            </label>
          </div>
        </div>
      </Info>
      <Info>
        <div>
          <div className="wrapper-info">
            <label>Placa</label>
            <label>
              {statusContracts?.veiculoPlaca
                ? statusContracts?.veiculoPlaca
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Renavam</label>
            <label>
              {statusContracts?.veiculoRenavam
                ? statusContracts?.veiculoRenavam
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Condutor</label>
            <label>
              {statusContracts?.condutorNome
                ? statusContracts?.condutorNome
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Apuração KM</label>
            <label>{odometer}</label>
          </div>
        </div>
        <div>
          <div className="wrapper-info">
            <label>Chassi</label>
            <label>
              {statusContracts?.veiculoChassi
                ? statusContracts?.veiculoChassi
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Modelo</label>
            <label>
              {statusContracts?.modeloNome ? statusContracts?.modeloNome : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Status</label>
            <label>
              {statusContracts?.statusVeiculoContratoNome
                ? statusContracts?.statusVeiculoContratoNome
                : '--'}
            </label>
          </div>
        </div>
      </Info>
      <Info>
        <div>
          <div className="wrapper-info">
            <label>Início da Vigência</label>
            <label>
              {statusContracts?.contratoVeiculoDataAbertura
                ? moment(statusContracts?.contratoVeiculoDataAbertura).format(
                    'DD/MM/YYYY'
                  )
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>Valor Mensal</label>
            <label>
              {statusContracts?.contratoVeiculoValorMensal
                ? formatValue(statusContracts?.contratoVeiculoValorMensal)
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>KM Excedente</label>
            <label>
              {statusContracts?.propostaItemManutencaoKmExcedente
                ? statusContracts?.propostaItemManutencaoKmExcedente
                : '--'}
            </label>
          </div>
        </div>
        <div>
          <div className="wrapper-info">
            <label>Final da Vigência</label>
            <label>
              {statusContracts?.contratoVeiculoDataFinal
                ? moment(statusContracts?.contratoVeiculoDataFinal).format(
                    'DD/MM/YYYY'
                  )
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label>KM Franquia Mensal</label>
            <label>
              {statusContracts?.contratoItemKmFranquia
                ? statusContracts?.contratoItemKmFranquia
                : '--'}
            </label>
          </div>
          <div className="wrapper-info">
            <label></label>
            <label>&nbsp;</label>
          </div>
        </div>
      </Info>
    </div>
  );
}

export default VehicleDetails;
